import { useCallback } from 'react';
import { calculationUrl, useBackendFetch } from 'services';

type DownloadFileType = (
  ev: React.MouseEvent,
  relativeUrl: string,
  fileName: string,
  additionalCallback?: () => void
) => Promise<void>;

const useFileDownloader = (): DownloadFileType => {
  const fetchBackend = useBackendFetch();

  const downloadFile = useCallback(
    async (ev, relativeUrl, fileName, additionalCallback?: any) => {
      ev.stopPropagation();

      const url = `${calculationUrl}${relativeUrl}`;

      const response = await fetchBackend(url);
      const result = await response.blob();
      const blob = new Blob([result], { type: result.type });

      const newUrl = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = newUrl;
      a.download = fileName || '';
      document.body.appendChild(a);
      a.click();

      // Execute the additional callback if provided
      if (additionalCallback) {
        additionalCallback();
      }
    },
    [fetchBackend]
  );

  return downloadFile;
};

export default useFileDownloader;
