import { Grid } from 'components/Grid';
import { useTranslation } from 'react-i18next';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { useMemo } from 'react';
import { useRequestCounterpartyRatingRiskMutation } from 'services';
import { useToast } from '../../../Toast';
import { useForm } from 'react-hook-form';
import { QuotaCounterpartyViewModel } from 'schema';
import { useQueryClient } from 'react-query';
import { Button } from '../../../Button';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      alignItems: 'center',
    },
    accordionDetails: {
      padding: theme.spacing(2.5),
      flexDirection: 'column',
    },
    title: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      background: theme.palette.background.paper,
      padding: theme.spacing(1.5, 0),
    },
    number: {
      '& input': {
        textAlign: 'right',
      },
    },
    locked: {
      filter: 'grayscale(1) brightness(1.2)',
    },
  })
);

type RisksProps = {
  risks: QuotaCounterpartyViewModel[];
  quotaId: number;
};

export const Risks = (props: RisksProps) => {
  const { risks, quotaId } = props;
  return (
    <div>
      {risks.map((risk) => (
        <Risk {...risk} quotaId={quotaId} key={risk.inn} />
      ))}
    </div>
  );
};

export const Risk = (props: QuotaCounterpartyViewModel & { quotaId: number }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const toast = useToast();
  const { inn, name, canRequestRatingRisk, counterpartyType, totalRisk, quotaId } = props;

  const queryClient = useQueryClient();

  const { mutateAsync } = useRequestCounterpartyRatingRiskMutation(quotaId, {
    onSuccess: () => {
      toast(t('SuccessMessage'), 'success');
      queryClient.invalidateQueries({
        predicate: (query) => {
          return query.queryKey.indexOf('quotas') > -1 || query.queryKey.indexOf('margin') > -1;
        },
      });
    },
    onError: () => {
      toast(t('ErrorMessage'), 'error');
    },
  });

  const { handleSubmit } = useForm<{ inn: string }>({
    mode: 'onBlur',
    defaultValues: {
      inn: inn,
    },
  });

  const onSubmit = useMemo(() => {
    const submit = async (form: { inn: string }) => {
      await mutateAsync(form);
    };
    return handleSubmit(submit);
  }, [handleSubmit, mutateAsync]);

  return (
    <form onSubmit={onSubmit}>
      <Grid container columnSpacing={2} rowSpacing={2.5} className={classes.root}>
        <Grid item xs={12}>
          {name}
          {counterpartyType}
        </Grid>
        <Grid item xs={8}>
          Уровень риска: {totalRisk}
        </Grid>
        <Grid item xs={4}>
          <Button variant="contained" type="submit" disabled={!canRequestRatingRisk}>
            {canRequestRatingRisk ? 'Запросить оценку' : 'Обрабатывается'}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};
