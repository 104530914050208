import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { IssueStatus, QuotaMarginViewModel } from 'schema';
import { themeOrange as theme } from 'theme';
import rangeBkg from '../../../../img/png/grap.png';
import { formatNumber } from '../../../utils';
import { RangeMark } from './RangeMark';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      padding: theme.spacing(0, 2.5, 0, 0.5),
    },
    root: {
      height: 14,
      display: 'flex',
      width: '100%',
      padding: 0,
      position: 'relative',
      backgroundImage: `url(${rangeBkg})`,
      '&:after': {
        content: "''",
        position: 'absolute',
        backgroundColor: theme.palette.text.secondary,
        width: 2,
        height: 16,
        top: -1,
        left: -3,
      },
      '&:before': {
        content: "'0%'",
        fontSize: 10,
        color: theme.palette.text.secondary,
        position: 'absolute',
        top: 1,
        left: -11,
        fontWeight: 600,
        transform: 'translateX(-50%)',
      },
    },
    bkg: {
      position: 'absolute',
      top: 0,
      left: 0,
      backgroundImage: `url(${rangeBkg})`,
      width: '100%',
      height: '100%',
      zIndex: 2,
    },
    forbidden: {
      position: 'relative',
      backgroundColor: '#FF5400',
      '&:after': {
        content: "''",
        position: 'absolute',
        display: 'block',
        width: 1,
        height: 14,
        right: 0,
        backgroundColor: theme.palette.common.white,
        zIndex: 1,
      },
    },
    possible: {
      position: 'relative',
      backgroundColor: theme.palette.warning.light,
    },
    label: {
      position: 'absolute',
      transform: 'translateX(-50%)',
      top: 33,
      left: 4,
      fontSize: 8,
      '&$right': {
        transform: 'translateX(0)',
      },
      '&$left': {
        transform: 'translateX(-100%)',
      },
    },
    value: {
      position: 'absolute',
      transform: 'translateX(-50%)',
      top: 22,
      left: 4,
      '&$right': {
        transform: 'translateX(0)',
      },
      '&$left': {
        transform: 'translateX(-100%)',
      },
    },
    right: {},
    left: {},
    green: {
      position: 'relative',
      backgroundColor: theme.palette.success.main,
      '&:after': {
        content: "''",
        position: 'absolute',
        backgroundColor: theme.palette.text.secondary,
        width: 2,
        height: 16,
        top: -1,
        right: -3,
      },
      '& div': {
        color: theme.palette.text.secondary,
        fontSize: 10,
        fontWeight: 600,
        position: 'absolute',
        top: 1,
        right: -14,
        transform: 'translateX(50%)',
      },
    },

    min: {
      position: 'absolute',
      left: (props: any) => {
        return `calc(${(props.min / props.max) * 100}% - 16px)`;
      },
      color: theme.palette.common.white,
      fontSize: 10,
      top: 1,
      fontWeight: 600,
    },

    approved: {
      position: 'absolute',
      width: 8,
      height: 20,
      top: -2,
      left: (props: any) => {
        return `calc(${(props.minGreen / props.max) * 100}% - 4px)`;
      },
      color: theme.palette.text.primary,
      fontSize: 12,
    },

    calculated: {
      position: 'absolute',
      width: 8,
      height: 20,
      top: -2,
      left: (props: any) => {
        return `calc(${(props.calculated / props.max) * 100}% - 4px)`;
      },
      color: theme.palette.text.primary,
      fontSize: 12,
    },

    minGreen: {
      position: 'absolute',
      width: 8,
      height: 20,
      top: -4,
      left: (props: any) => {
        return `calc(${(props.minGreen / props.max) * 100}% - 4px)`;
      },
      color: theme.palette.text.primary,
      fontSize: 12,
      '& div': {
        position: 'absolute',
        transform: 'translateX(-50%)',
        top: -14,
        left: 4,
      },
      '&:after': {
        content: "'Утверждаемая'",
        textAlign: 'center',
        position: 'absolute',
        fontSize: 8,
        top: -24,
        left: 0,
        width: 60,
        transform: 'translateX(-50%)',
      },
    },

    current: {
      position: 'absolute',
      width: 8,
      height: 20,
      top: -4,
      left: (props: any) => {
        return `calc(${(props.current / props.max) * 100}% - 4px)`;
      },
      color: theme.palette.text.primary,
      fontSize: 12,
      '& div': {
        position: 'absolute',
        transform: 'translateX(-50%)',
        top: -14,
        left: 4,
      },
      '&:after': {
        content: "'В расчёте'",
        textAlign: 'center',
        position: 'absolute',
        fontSize: 8,
        top: (props: any) => {
          return props.isMarginApprove ? 33 : -24;
        },
        left: 0,
        width: 40,
        transform: 'translateX(-50%)',
      },
    },
  })
);

export const Range = (props: QuotaMarginViewModel & { isMarginApprove?: boolean }) => {
  const classes = useStyles(props);

  const {
    min,
    max,
    current,
    minGreen = min,
    calculated = 0,
    isMarginApprove,
    isStartup,
    marginIssue: issue,
  } = props;
  const forbiddenWidth = minGreen < min ? (minGreen / max) * 100 : (min / max) * 100;
  const possibleWidth = minGreen < min ? 0 : (minGreen / max) * 100 - forbiddenWidth;
  const approvedWidth = 100 - (possibleWidth + forbiddenWidth);

  const isEqual = (first: number, second: number) => {
    return Number(first.toFixed(2)) === Number(second.toFixed(2));
  };

  const getLabelPosition = (firstLabel: number, secondLabel: number) => {
    const isClose = Math.abs(firstLabel - secondLabel) < 2.5 && !isEqual(firstLabel, secondLabel);
    return isClose ? (firstLabel > secondLabel ? 'right' : 'left') : 'bottom';
  };

  return (
    <div className={classes.wrapper}>
      <div className={classes.root}>
        <div className={classes.forbidden} style={{ width: `${forbiddenWidth}%` }} />
        {!!minGreen && <div className={classes.possible} style={{ width: `${possibleWidth}%` }} />}
        <div className={classes.green} style={{ width: `${approvedWidth}%` }}>
          <div>{formatNumber(max, 2, true)}%</div>
        </div>

        {!isMarginApprove && (
          <div className={classes.current}>
            <div>{formatNumber(current, 2, true)}%</div>
            <svg
              width="8"
              height="20"
              viewBox="0 0 8 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect x="2" y="2" width="4" height="18" fill="white" />
              <path
                d="M5 20L3 20L3 3L-1.74846e-06 6.99382e-07L2 5.24537e-07L4 2L6 1.74846e-07L8 0L5 3L5 20Z"
                fill={current < minGreen ? theme.palette.primary.main : theme.palette.success.main}
              />
              )
            </svg>
          </div>
        )}

        {!isStartup && (
          <div className={classes.min}>
            <div>{formatNumber(min, 2, true)}%</div>
          </div>
        )}

        <RangeMark
          max={max}
          label={
            isMarginApprove && isEqual(current, calculated)
              ? 'Расчётная и В расчёте'
              : !isMarginApprove &&
                issue?.status === IssueStatus.Closed &&
                isEqual(minGreen, calculated)
              ? 'Расчётная и Утверждённая'
              : 'Расчётная'
          }
          position={
            isStartup
              ? 'bottom'
              : getLabelPosition(calculated, isMarginApprove ? current : minGreen)
          }
          value={calculated}
        />

        {isMarginApprove && !isEqual(current, calculated) && (
          <RangeMark
            max={max}
            label="В расчёте"
            position={getLabelPosition(current, calculated)}
            value={current}
          />
        )}

        {!isMarginApprove &&
          issue?.status === IssueStatus.Closed &&
          !isEqual(minGreen, calculated) && (
            <RangeMark
              max={max}
              label="Утверждённая"
              position={getLabelPosition(minGreen, calculated)}
              value={minGreen}
            />
          )}

        {isMarginApprove && (
          <div className={classes.minGreen}>
            <div>{formatNumber(minGreen, 2, true)}%</div>
            <svg
              width="8"
              height="20"
              viewBox="0 0 8 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect x="2" y="2" width="4" height="18" fill="white" />
              <path
                d="M5 20L3 20L3 3L-1.74846e-06 6.99382e-07L2 5.24537e-07L4 2L6 1.74846e-07L8 0L5 3L5 20Z"
                fill={theme.palette.text.primary}
              />
            </svg>
          </div>
        )}

        <div className={classes.bkg} />
      </div>
    </div>
  );
};
