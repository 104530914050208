import { Divider, Theme, createStyles, makeStyles } from '@material-ui/core';
import { CalculationFormProps, useCalculationFormContext } from '../types';
import { Grid, Radio, Switch } from 'components';
import { useTranslation } from 'react-i18next';
import { LeaseProductAutocomplete } from './LeaseProductAutocomplete';
import { CounterpartyAutocomplete } from '../CounterpartyAutocomplete';
import { Currency } from 'schema/serverTypes';
import { NomenclaturesList } from './NomenclaturesList';
import { SaleTotalPriceInput } from './SaleTotalPriceInput';
import { LeaseTotalPriceInput } from './LeaseTotalPriceInput';
import { CurrencyRateInput } from './CurrencyRateInput';
import { IndustryLeaseProductAutocomplete } from './IndustryLeaseProductAutocomplete';
import { VatInput } from './VatInput';
import { LessseeAutocomplete } from '../LesseeAutocomplete';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      width: '100%',
    },
    divider: {
      marginBottom: theme.spacing(2.5),
    },
    commissionLabel: {
      textAlign: 'right',
      '& .MuiGrid-root': {
        flexWrap: 'nowrap',
      },
      '& .MuiFormControlLabel-root': {
        marginRight: 0,
      },
    },
    nomenclatureOrderLink: {
      fontSize: 12,
      color: theme.palette.dllBlue.main,
      marginLeft: theme.spacing(1),
    },
    add: {
      color: theme.palette.primary.light,
    },
  })
);

export type SaleContractFieldsProps = Pick<
  CalculationFormProps,
  'quotaId' | 'calculationResult' | 'nomenclatures'
> & {
  onOpenNomenclatures: () => void;
};

export const SaleContractFields = (props: SaleContractFieldsProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { onOpenNomenclatures, calculationResult, quotaId, nomenclatures = [] } = props;

  const margins = calculationResult?.nomenclatureMargins ?? [];

  const { control, watch, setValue } = useCalculationFormContext();

  return (
    <div className={classes.wrapper}>
      <Grid container columnSpacing={2} rowSpacing={2.5}>
        <Grid item xl={7} md={7} xs={24}>
          <LeaseProductAutocomplete />
        </Grid>
        <Grid item xl={5} md={5} xs={24}>
          <IndustryLeaseProductAutocomplete />
        </Grid>
        <Grid item xl={12} md={12} xs={24} xsStart={1}>
          <CounterpartyAutocomplete type="dealer" />
        </Grid>
        <Grid item xl={12} md={12} xs={24}>
          <LessseeAutocomplete />
        </Grid>
        <NomenclaturesList
          addButtomCssClassName={classes.add}
          onOpenNomenclatures={onOpenNomenclatures}
          nomenclatures={nomenclatures}
          margins={margins}
          quotaId={quotaId}
        />
      </Grid>
      <Divider light className={classes.divider} />
      <Grid container columnSpacing={2} rowSpacing={2.5}>
        <Grid item md={'auto'} xs={24}>
          <Switch
            name="vatEnabled"
            label={t('Vat')}
            labelOff={''}
            labelOn={''}
            type="checkbox"
            control={control}
            classes={{ root: classes.commissionLabel }}
          />
        </Grid>
        <Grid item xl={3} lg={3} md={3} xs={24}>
          <VatInput watch={watch} control={control} setValue={setValue} />
        </Grid>
        <Grid item xl={7} lg={7} md={6} xs={24}>
          <SaleTotalPriceInput />
        </Grid>
        <Grid item md={'auto'} xs={24}>
          <Radio
            name="currencySale"
            label={t('Currencies.Sale')}
            control={control}
            options={[
              { label: '₽', value: Currency.Ruble },
              { label: '$', value: Currency.Dollar },
              { label: '€', value: Currency.Euro },
              { label: '¥', value: Currency.Yuan },
            ]}
          />
        </Grid>
        <Grid item xl={4} lg={5} md={5} xs={24}>
          <CurrencyRateInput />
        </Grid>
        <Grid item md={'auto'} xs={24}>
          <Switch
            name="currencyCommission"
            label={t('Currencies.ConversionFee')}
            labelOn={t('Yes')}
            labelOff={t('No')}
            type="checkbox"
            control={control}
            classes={{ root: classes.commissionLabel }}
          />
        </Grid>
        <Grid item xl={6} lg={6} md={8} xs={24}>
          <LeaseTotalPriceInput />
        </Grid>
      </Grid>
    </div>
  );
};
