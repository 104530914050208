import { useQuotaMarginDetailsQuery } from 'services';
import { MarginApprove } from './MarginApprove';
import { MarginSave } from './MarginSave';

export const Margin = (props: { quotaId: number }) => {
  const { quotaId } = props;

  const { data } = useQuotaMarginDetailsQuery(quotaId);
  if (!data) return null;
  const CAN_APPROVE = 4;

  if (
    (data.marginIssueActions.canApproveRed && data.current < CAN_APPROVE) ||
    (data.marginIssueActions.canApproveYellow && data.current >= CAN_APPROVE)
  ) {
    return <MarginApprove data={data} canApprove={CAN_APPROVE} />;
  } else {
    return <MarginSave data={data} quotaId={quotaId} />;
  }
};
