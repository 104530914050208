import { Grid } from 'components/Grid';
import { Range } from './Range';
import { NumberFormatCustom } from '../../CalculationForm/AmountInput';
import { useTranslation } from 'react-i18next';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  createStyles,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import { MessageButton } from './MessageButton';
import { useEffect, useMemo } from 'react';
import { useApproveMarginIssueMutation } from 'services';
import { useToast } from '../../../Toast';
import { useForm } from 'react-hook-form';
import { Input } from 'components/form';
import { QuotaMarginViewModel } from 'schema';
import { useQueryClient } from 'react-query';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      alignItems: 'center',
    },
    accordionDetails: {
      padding: theme.spacing(2.5),
      flexDirection: 'column',
    },
    title: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      background: theme.palette.background.paper,
      padding: theme.spacing(1.5, 0),
    },
    number: {
      '& input': {
        textAlign: 'right',
      },
    },
    locked: {
      filter: 'grayscale(1) brightness(1.2)',
    },
  })
);

type MarginApproveProps = {
  data: QuotaMarginViewModel;
  canApprove: number;
};

export const MarginApprove = (props: MarginApproveProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const toast = useToast();
  const { data, canApprove } = props;

  const queryClient = useQueryClient();

  const { mutateAsync, isLoading } = useApproveMarginIssueMutation(data.quotaId, {
    onSuccess: () => {
      toast(t('SuccessMessage'), 'success');
      queryClient.invalidateQueries({
        predicate: (query) => {
          return query.queryKey.indexOf('quotas') > -1 || query.queryKey.indexOf('margin') > -1;
        },
      });
    },
    onError: () => {
      toast(t('ErrorMessage'), 'error');
    },
  });

  const defaultValues = useMemo(() => {
    return {
      margin: data.marginIssue?.marginApproval?.margin,
    };
  }, [data]);

  const {
    control,
    handleSubmit,
    watch,
    formState: { isSubmitSuccessful, isDirty },
    reset,
  } = useForm<{ margin: number }>({
    mode: 'onBlur',
    defaultValues,
  });

  useEffect(() => {
    reset({ margin: data.current });
  }, [reset, data]);

  //resets isDirty after successful submit
  useEffect(() => {
    if (isSubmitSuccessful) {
      reset({}, { keepValues: true });
    }
  }, [isSubmitSuccessful, reset]);

  const onSubmit = useMemo(() => {
    const submit = async (form: { margin: number }) => {
      await mutateAsync(form);
    };
    return handleSubmit(submit);
  }, [handleSubmit, mutateAsync]);

  const currentMargin = watch('margin');

  const isLessee = !!data.lesseeInn;

  return (
    <Accordion expanded={true}>
      <AccordionSummary aria-controls="panel3a-content" id="panel3a-header">
        <div className={classes.title}>
          <Typography variant="subtitle1">Маржа</Typography>
        </div>
      </AccordionSummary>
      <AccordionDetails className={classes.accordionDetails}>
        <form onSubmit={onSubmit} className={data.isLocked ? classes.locked : ''}>
          <Grid container columnSpacing={2} rowSpacing={2.5} className={classes.root}>
            <Grid item xs={11}>
              <Range {...{ ...data, minGreen: currentMargin }} isMarginApprove={true} />
            </Grid>
            <Grid item xs={3}>
              <Input
                label={`Утверждаемая, %`}
                name="margin"
                control={control}
                disabled={data.isLocked}
                InputProps={{
                  inputComponent: NumberFormatCustom,
                }}
                rules={{
                  pattern: /^[0-9,\-\s]+$/i,
                  min: {
                    value: data.marginIssueActions.canApproveYellow ? canApprove : 0,
                    message: `Минимум ${
                      data.marginIssueActions.canApproveYellow ? canApprove : 0
                    }%`,
                  },
                  max: {
                    value: data.marginIssueActions.canApproveYellow ? data.max : canApprove,
                    message: `Максимум ${
                      data.marginIssueActions.canApproveYellow ? data.max : canApprove
                    }%`,
                  },
                }}
              />
            </Grid>
            <Grid item xs={10}>
              <MessageButton
                current={currentMargin}
                calculated={data.calculated ? data.calculated : data.min}
                minGreen={data.minGreen ? data.minGreen : data.min}
                min={data.min}
                isLocked={data.isLocked}
                issue={data.marginIssue}
                isContractIssue={!!data.contractIssue}
                isLoading={isLoading}
                isDirty={isDirty}
                isLessee={isLessee}
                isStartup={data.isStartup}
                approveActions={data.marginIssueActions}
                isMarginApprove={true}
              />
            </Grid>
          </Grid>
        </form>
      </AccordionDetails>
    </Accordion>
  );
};
